.image-gallery {
    width: 100%;
    height: 500px;
}

.image-gallery-slide img {
    width: 100%;
    height: 500px;
    overflow: hidden;
    object-position: center center; 
}
.image-gallery-slide .image-gallery-image 
{
    object-fit: cover !important;
}

.fullscreen .image-gallery-slide img {
    height: 80vh;
     object-fit: contain !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover {

    border: 4px solid #ffb400 !important ;
}
.image-gallery-thumbnails 
{
    height:  200px !important;
}
.image-gallery-icon:hover
{
    color: #ffb400 !important;
}