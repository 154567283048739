.InfiniteCarouselDots
{
    position: absolute; 
    padding: 0;

    display: flex;
    justify-content: center;
    flex-flow: wrap;
}
.InfiniteCarouselDot
{
    margin: 2px !important;
}
@media (max-width: 676px) 
{


}

@media (max-width: 500px) 
{
    .lazy-load-image-background.blur.lazy-load-image-loaded 
    {
        height: 300px !important;
    }

}
@media (max-width: 430px) 
{
    .lazy-load-image-background.blur.lazy-load-image-loaded 
    {
        height: 250px !important;
    }
    .lazy-load-image-background.blur.lazy-load-image-loaded img
    {
        height: 100%;
    }
}
@media (max-width: 372px) 
{
    .lazy-load-image-background.blur.lazy-load-image-loaded 
    {
        height: 250px !important;
    }
    .lazy-load-image-background.blur.lazy-load-image-loaded img
    {
        height: 100%;
    }
}

@media (max-width: 344px) 
{
    .lazy-load-image-background.blur.lazy-load-image-loaded 
    {
        height: 200px !important;
    }
    .lazy-load-image-background.blur.lazy-load-image-loaded img
    {
        height: 100%;
    }
}